/* eslint-disable max-depth */
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import StatsService from '@/services/assistant/stats.service.js'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { Stats } from '@/classes/stats/assistants/stats.js'
import DateRange from '@/components/DateRange/DateRange.vue'
import VueApexCharts from 'vue-apexcharts'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import { ChatBotsGraphicData } from '@/classes/stats/ChatBotsGraphicData'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import getEnv from '@/util/env'

export default {
  name: 'chatbotStats',
  components: {
    HeaderTopDashboard,
    StatCard,
    DateRange,
    apexchart: VueApexCharts,
    RadialBar,
    StatsTitle,
  },

  data () {
    return {
      stats: new Stats(),
      cards: [],
      chatbotsPerformanceItems: [],
      search: '',
      tasks: [],
      lastInteractions: [],
      chatbotPerformanceDates: new GlobalControl(),
      chatbotRatingDates: new GlobalControl(),
      tasksDates: new GlobalControl(),
      ready: false,

      tasksSearch: '',

      interactionsPage: 1,
      interactionsItemsPerPage: 5,
      interactionsSearch: '',

      performancePage: 1,
      performanceItemsPerPage: 5,
      performanceSearch: '',


      interactionsPageCount: 0,
      performancePageCount: 0,

      performanceByHourAndDaysOfWeekData: [],

      ratingSummarySearch: '',
      rateSummaryPage: 1,
      ratingSummaryItemsPerPage: 5,
      rateSummaryPageCount: 0,

      chartOptions: {
        chart: {
          height: '150px',
          type: 'heatmap',
        },
        dataLabels: {
          enabled: true,
        },
        colors: ['#008FFB'],
        title: {
          text: this.$t('Rendimiento por horas'),
        },
        xaxis: {
          type: 'numeric',
          tickAmount: 24,
          min: 0,
          max: 23,
          labels: {
            formatter: function (value) {
              return Math.round(value)
            },
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

            return '<span class="pa-3">' +
              '<b>' + w.globals.initialSeries[seriesIndex].name + ' a las ' + data.x + ' horas</b>: ' + data.y + ' conversaciones</span>'
          },
        },
      },
      heatMapPeries: undefined,
      readyHeatMap: true,
      selectedChannel: 'both',
      selectedRatingChannel: 'both',
      chatBotsGraphicData: null,
      chatBotsPendingGraphicData: null,
      readyRadialBar: false,

      satisfactionItems: [],

    }
  },
  methods: {

    downloadChatbotPerformanceCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/ai/assistant/stats/export-chatbots-performance/?token=' + user.token +
        '&initDate=' + this.chatbotPerformanceDates.initDate +
        '&endDate=' + this.chatbotPerformanceDates.endDate
      window.location.href = url
    },


    downloadTasksPerformanceCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/ai/assistant/stats/export-tasks-performance/?token=' + user.token +
        '&initDate=' + this.tasksDates.initDate +
        '&endDate=' + this.tasksDates.endDate
      window.location.href = url
    },

    downloadLastInteractionsCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/ai/assistant/stats/export-last-interactions/?token=' + user.token + '&channel=' + this.selectedChannel
      window.location.href = url
    },

    downloadRatingSummaryCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const url = getEnv('VUE_APP_API_URL') + 'api/ai/assistant/stats/export-rating-summary-csv/?token=' + user.token +
        '&channel=' + this.selectedRatingChannel +
        '&initDate=' + this.chatbotRatingDates.initDate +
        '&endDate=' + this.chatbotRatingDates.endDate
      window.location.href = url
    },

    getTotalFor (data, field) {
      return data.reduce((sum, task) => sum + (parseInt(task[field]) || 0), 0)
    },
    changeSelectedChannel () {
      this.getLastInteractions()
    },
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    selectChatbotPerformanceDates (dates) {
      this.chatbotPerformanceDates.initDate = dates[0]
      this.chatbotPerformanceDates.endDate = dates[1]
      this.getChatbotsPerformance()
    },

    selectChatbotRatingDates (dates) {
      this.chatbotRatingDates.initDate = dates[0]
      this.chatbotRatingDates.endDate = dates[1]
      this.getRatingSummaryData()
    },

    selectTasksDates (dates) {
      this.tasksDates.initDate = dates[0]
      this.tasksDates.endDate = dates[1]
      this.getTasksData()
    },

    getStats () {
      StatsService.getStats().then((res) => {
        this.stats.setData(res)
        this.cards = this.stats.getCardsStats()
      })
    },

    getChatbotsPerformance () {
      const filters = {
        initDate: this.chatbotPerformanceDates.initDate,
        endDate: this.chatbotPerformanceDates.endDate,
      }
      StatsService.getChatbotsPerformance(filters).then((res) => {
        this.chatbotsPerformanceItems = res.map(item => {
          item.closedCalculated = item.total - (item.webChatOpenTotal + item.whatsappOpenTotal)
          item.openCalculated = item.webChatOpenTotal + item.whatsappOpenTotal
          return item
        })
      })
    },

    getRatingSummaryData () {
      const filters = {
        initDate: this.chatbotRatingDates.initDate,
        endDate: this.chatbotRatingDates.endDate,
        channel: this.selectedRatingChannel,
      }
      StatsService.getAgentRatingsSummary(filters).then((res) => {
        this.satisfactionItems = res
      })
    },

    getTasksData () {
      const filters = {
        initDate: this.tasksDates.initDate,
        endDate: this.tasksDates.endDate,
      }
      StatsService.getTasksData(filters).then((res) => {
        this.tasks = res

        const totals = {
          totalPending: this.getTotalFor(this.tasks, 'totalPending'),
          totalComplete: this.getTotalFor(this.tasks, 'totalComplete'),
          total: this.getTotalFor(this.tasks, 'total'),
        }
        const chatBotsGraphicData = new ChatBotsGraphicData({ records: this.tasks, totals: totals }, this)
        this.chatBotsGraphicData = chatBotsGraphicData.getTasksGraphicData()
        this.chatBotsPendingGraphicData = chatBotsGraphicData.getTasksPendingGraphicData()

        this.readyRadialBar = true
      })
    },

    getLastInteractions () {
      StatsService.getLastInteractions({ channel: this.selectedChannel }).then((res) => {
        this.lastInteractions = res
      })
    },

    getPerformanceByHourAndDaysOfWeek () {
      StatsService.getPerformanceByHourAndDaysOfWeek().then((res) => {
        this.performanceByHourAndDaysOfWeekData = res

        this.heatMapPeries = this.formatHeatMapSeries()
        this.readyHeatMap = true
      })
    },

    formatHeatMapSeries () {
      const heatMapSeries = []
      const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
      daysOfWeek.forEach((dayName, dayIndex) => {
        const dayData = this.performanceByHourAndDaysOfWeekData.filter(data => data.day_of_week === dayIndex)
        const formattedData = dayData.map(item => ({
          x: item.hour,
          y: Number(item.total_conversations),
        }))

        heatMapSeries.push({
          name: this.$t(dayName),
          data: formattedData,
        })
      })
      return heatMapSeries.reverse()
    },
  },
  created () {},
  mounted () {
    this.chatbotPerformanceDates.setCurrentMontDates()
    this.chatbotRatingDates.setCurrentMontDates()

    this.tasksDates.setCurrentMontDates()

    this.resetReady()

    this.getStats()
    this.getChatbotsPerformance()
    this.getTasksData()
    this.getLastInteractions()
    this.getPerformanceByHourAndDaysOfWeek()
    this.getRatingSummaryData()
  },
  computed: {
    channelOptions () {
      return [
        { text: this.$t('Todos'), value: 'both' },
        { text: 'Web Chat', value: 'web-chat' },
        { text: 'WhatsApp', value: 'whatsapp' },
      ]
    },

    interactionsDisabledPagination () {
      return this.lastInteractions.length <= this.interactionsItemsPerPage
    },

    performanceDisabledPagination () {
      return this.chatbotsPerformanceItems.length <= this.performanceItemsPerPage
    },

    ratingSummaryDisabledPagination () {
      return this.satisfactionItems.length <= this.ratingSummaryItemsPerPage
    },

    satisfactionHeaders () {
      return [
        {
          text: this.$t('Agente'),
          value: 'agentName',
          width: '40%',
        },
        {
          text: this.$t('Estrellas'),
          value: 'averageRateFixed',
          width: '40%',
        },
      ]
    },

    performanceHeaders () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'name',
          width: '40%',
        },
        {
          text: this.$t('Conv. Total'),
          value: 'total',
          width: '12%',
        },
        {
          text: this.$t('Mensajes enviados'),
          value: 'totalSent',
          width: '12%',
        },
        {
          text: this.$t('Mensajes recibidos'),
          value: 'totalContact',
          width: '12%',
        },
        {
          text: this.$t('Conv. cerradas'),
          value: 'closedCalculated',
          width: '12%',
        },
        {
          text: this.$t('Conv. pendientes'),
          value: 'openCalculated',
          width: '12%',
        },
      ]
    },

    taskHeaders () {
      return [
        {
          text: this.$t('Tipo de tarea'),
          value: 'name',
          width: '50%',
        },
        {
          text: this.$t('Pendientes'),
          value: 'totalPending',
          width: '16%',
        },
        {
          text: this.$t('Resultas'),
          value: 'totalComplete',
          width: '16%',
        },
        {
          text: this.$t('Total'),
          value: 'total',
          width: '16%',
        },
      ]
    },

    interactionHeaders () {
      return [
        {
          text: this.$t('Agente'),
          value: 'name',
          width: '40%',
        },
        {
          text: this.$t('Usuario'),
          value: 'contact_name',
          width: '12%',
        },
        {
          text: this.$t('Estado'),
          value: 'is_open',
          width: '12%',
        },
        {
          text: this.$t('Fecha'),
          value: 'date',
          width: '12%',
        },
        {
          text: this.$t('Hora'),
          value: 'time',
          width: '12%',
        },
        {
          text: this.$t('Canal'),
          value: 'channel',
          width: '12%',
        },
      ]
    },
  },
}
