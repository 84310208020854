
export class Stats {
  constructor () {
    this.cards = []
    this.conversationsPlanAvailable = 1000
  }

  safeDivide (numerador, denominador) {
    return denominador !== 0 ? numerador / denominador : 0
  }

  calculatePercentage (numerador, denominador) {
    return this.safeDivide(numerador, denominador) * 100
  }

  setData (data) {
    console.log(data)
    const availableConversations = this.conversationsPlanAvailable - (data.totalConversations + data.webChatOpenConversations + data.whatsappOpenConversations)

    const percentageAvailable = this.calculatePercentage(availableConversations, this.conversationsPlanAvailable)

    const totalMinutesConversations = (data.webChatAverageConversationsData.totalTimeSpent + data.whatsappAverageConversationsData.totalTimeSpent) / 60

    const channelsTotalConversations = data.webChatAverageConversationsData.totalConversations + data.whatsappAverageConversationsData.totalConversations

    let averageMinutesDuration = this.safeDivide(totalMinutesConversations, channelsTotalConversations)
    averageMinutesDuration = parseFloat(averageMinutesDuration.toFixed(1))

    const totalClosedConversations = data.totalConversations - data.webChatOpenConversations - data.whatsappOpenConversations

    const percentageClosed = this.calculatePercentage(totalClosedConversations, this.conversationsPlanAvailable)

    const percentageSatisfaction = this.calculatePercentage(data.rateGeneralAverage, 5)



    this.cards = [
      {
        title: 'Conversaciones disponibles',
        subTitle: `${availableConversations} de ${this.conversationsPlanAvailable}`,
        percentage: percentageAvailable,
        colorClass: 'gradient-standard',
        percentageColorClass: 'blue',
      },
      {
        title: 'Tiempo medio de conversación',
        subTitle: `${averageMinutesDuration} Minutos`,
        percentage: null,
        colorClass: 'gradient-success',
        percentageColorClass: 'green',
      },
      {
        title: 'Satisfacción',
        titlePaddinBottomStyle: 'padding-bottom: 14px;',
        subTitle: data.totalNumberRate + ' valoraciones',
        percentage: percentageSatisfaction,
        customHeadingRightText: data.rateGeneralAverage + '/' + 5,
        colorClass: 'gradient-success',
        percentageColorClass: 'green',
      },
      {
        title: 'Conversaciones cerradas',
        subTitle: totalClosedConversations,
        percentage: percentageClosed,
        colorClass: 'gradient-danger',
        percentageColorClass: 'danger',
      },
    ]
  }

  getCardsStats () {
    return this.cards
  }
}
